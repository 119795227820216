import { abortNavigation, defineNuxtRouteMiddleware, useNuxtApp } from 'nuxt/app';
import { storeToRefs } from 'pinia';
import type { RouteLocationNormalized } from 'vue-router';

import { getMemberProductRoleApi } from '@/apis/member.api';
import { showAlert } from '@/composables/useDialog';
import { PRODUCT_ACCESS_DENY_ERROR_CODE } from '@/constants/error.const';
import type { ROLE_ID } from '@/constants/permission.const';
import { ERROR_PAGE_NAME } from '@/constants/url.const';
import { Permission } from '@/enums/common.enum';
import useProductStore from '@/stores/product.store';
import { useUserStore } from '@/stores/user.store';
import type { RoleAuthority } from '@/types/member/member-response.type';
import { isDirectAccess, redirectTo } from '@/utils/common.util';

export default defineNuxtRouteMiddleware(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    const { params, meta, name: toName } = to;
    const { name: fromName } = from;
    const { groupId, productId } = params;
    const nuxtApp = useNuxtApp();
    const t = (nuxtApp.$i18n as any)?.t;

    const userStore = useUserStore();
    const productStore = useProductStore();
    const { isOwner } = storeToRefs(userStore);
    const { isProductWritable } = storeToRefs(productStore);
    const roleAuthorityId = meta.roleAuthorityId as typeof ROLE_ID;

    if (isOwner.value) {
      isProductWritable.value = true;
      return;
    }
    let authorities: RoleAuthority[] = [];
    try {
      const role = await getMemberProductRoleApi({
        groupId: groupId.toString(),
        productNo: Number(productId)
      });
      authorities = role?.memberRole?.authorities ?? [];
    } catch (error) {
      // console.log('error :>> ', error);
    }
    if (authorities.length > 0 && !roleAuthorityId) {
      // Product home
      return;
    }
    const auth = authorities.find(
      (auth: RoleAuthority) => auth.roleAuthorityId === roleAuthorityId
    );
    if (auth?.permission.includes(Permission.READ)) {
      isProductWritable.value = auth?.permission === Permission.WRITE;
      return;
    }

    isProductWritable.value = false;

    const msg = 'studio.common.popup_case_g_not_view';

    if (isDirectAccess(toName, fromName)) {
      return await redirectTo(ERROR_PAGE_NAME, {
        toType: 'name',
        query: { code: PRODUCT_ACCESS_DENY_ERROR_CODE }
      });
    }
    await showAlert({ content: t(msg) });
    return abortNavigation();
  }
);
